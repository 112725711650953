<template>
  <el-dialog
    :title="type == '1' ? '创建短信追缴任务' : '创建电话追缴任务'"
    :visible.sync="dialogVisible"
    width="68%"
    center
    :before-close="handleClose"
  >
    <div>
      <div class="object-box">
        <div class="title-text fw600">追缴对象</div>
        <div class="group-box">
          <div class="item-box">
            <el-radio disabled v-model="formInline.radioDisabled" label="1"
              >符合「追缴启动条件」的所有车辆</el-radio
            >
          </div>
          <div class="item-box">
            <el-checkbox v-model="formInline.amountCheckbox"></el-checkbox>
            <div class="line-box">
              <span class="label-box">欠费金额：</span>
              <el-input
                type="text"
                v-model="formInline.debtMoneyStart"
                style="width: 120px"
                maxlength="9"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input
              ><span class="mglr8">至</span>
              <el-input
                type="text"
                v-model="formInline.debtMoneyEnd"
                style="width: 120px"
                maxlength="9"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input
              ><span class="mgl8">元</span><span class="tips-text">（注：不填写，默认为全部）</span>
            </div>
          </div>
          <div class="item-box">
            <el-checkbox v-model="formInline.orderCheckbox"></el-checkbox>
            <div class="line-box">
              <span class="label-box">欠费订单数：</span>
              <el-input
                type="text"
                v-model="formInline.debtOrderNumStart"
                style="width: 120px"
                maxlength="9"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input
              ><span class="mglr8">至</span>
              <el-input
                type="text"
                v-model="formInline.debtOrderNumEnd"
                style="width: 120px"
                maxlength="9"
                placeholder="请输入"
                oninput="value=value.replace(/[^\d]/g,'')"
              ></el-input
              ><span class="mgl8">笔</span><span class="tips-text">（注：不填写，默认为全部）</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == '1'">
        <div>
          <div class="title-text fw600">追缴短信</div>
          <div class="group-box line-txt">
            <el-select v-model="template" placeholder="请选择" @change="setChange">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.templateName"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <span class="title-desc">短信模板取自运营营销-短信管理</span>
            <p class="mr4 fc999" v-if="smsContent">{{ smsContent | setSms }}</p>
          </div>

          <!-- <div>
            <div class="group-box line-txt">
              亲爱的
              <span class="bold-text">{【车牌号】}</span>
              车主，您好，目前您的停车产生欠费，请尽快通过<span class="bold-text"
                >{ 车主移动端名车 }</span
              >进行车辆缴费，谢谢合作。
            </div>
            <div class="group-box line-txt">
              示例：亲爱的【辽AEE001】车主，您好，目前您的停车产生欠费，请尽快通过沈阳泊车APP进行车辆缴费，谢谢合作。
            </div>
          </div> -->
        </div>
        <div>
          <div class="title-text fw600">追缴时间</div>
          <div class="group-box">
            <el-radio-group v-model="formInline.recoverType">
              <el-radio label="1">立即发送</el-radio>
              <el-radio label="2">定时发送</el-radio>
            </el-radio-group>
            <el-date-picker
              v-if="formInline.recoverType == 2"
              :picker-options="pickerOptions"
              v-model="formInline.recoverTime"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择发送时间"
              class="date-box"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleCeatedTask" :loading="loading">创 建</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { compareSize } from "@/common/js/utils.js";
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      formInline: {
        debtMoneyStart: "",
        debtMoneyEnd: "",
        debtOrderNumStart: "",
        debtOrderNumEnd: "",
        radioDisabled: "1",
        amountCheckbox: false,
        orderCheckbox: false,
        recoverType: "1",
        recoverTime: "",
        smsTemplateId: "",
      },
      loading: false,
      template: "",
      options: [],
      smsContent: "",
    };
  },
  filters: {
    setSms(val) {
      // 暂时固定参数
      // eslint-disable-next-line no-template-curly-in-string
      return val.replace("${plateNumber}", "【辽AEE001】").replace("${debtMoney}", "12.98");
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleCeatedTask() {
      const parmas = {
        taskType: this.type,
      };
      const { debtMoneyStart, debtMoneyEnd, debtOrderNumStart, debtOrderNumEnd } = this.formInline;
      if (!compareSize(debtMoneyStart, debtMoneyEnd, "欠费金额")) return;
      if (!compareSize(debtOrderNumStart, debtOrderNumEnd, "欠费订单数")) return;
      if (
        this.formInline.recoverType == "2" &&
        new Date(this.formInline.recoverTime).getTime() < Date.now()
      ) {
        this.$alert("定时时间不能小于当前时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      if (this.formInline.recoverType == "2" && !this.formInline.recoverTime) {
        return this.$alert("请选择发送时间", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      if (this.formInline.id == "") {
        return this.$alert("请选择短信模板", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
      }
      if (this.formInline.amountCheckbox) {
        parmas.debtMoneyStart = this.formInline.debtMoneyStart * 100 || "";
        parmas.debtMoneyEnd = this.formInline.debtMoneyEnd * 100 || "";
      }
      if (this.formInline.orderCheckbox) {
        parmas.debtOrderNumStart = this.formInline.debtOrderNumStart;
        parmas.debtOrderNumEnd = this.formInline.debtOrderNumEnd;
      }
      if (this.type == "1") {
        parmas.recoverType = this.formInline.recoverType;
        parmas.recoverTime = this.formInline.recoverTime;
      }
      this.loading = true;
      let opt = {
        method: "post",
        url: "/acb/2.0/recoverTask/create",
        data: { ...parmas, smsTemplateId: this.formInline.smsTemplateId },
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              message: "创建成功",
              type: "success",
            });
            this.$emit("back");
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    setChange(value) {
      this.formInline.smsTemplateId = value;
      this.smsContent = this.options.find((item) => {
        return item.id == value;
      }).content;
    },
    // 下拉模板
    getSpinner() {
      console.log("下拉模板");
      this.$axios.get("/acb/2.0/memberSmsTask/getMemberSmsTemplate").then((res) => {
        this.options = res.value;
      });
    },
  },
  created() {
    this.getSpinner();
  },
};
</script>
<style lang="stylus" scoped>
.title-text {
  position: relative;
  font-size: 14px;
  color: #2F2F51;
  padding-left: 12px;
  // margin: 8px 0;
  margin-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4px;
    height: 15px;
    background: #0C66FF;
  }
}

.group-box {
  width: 100%;
  padding: 6px 0 16px 26px;
  box-sizing: border-box;
  position: relative;

  .date-box {
    position: absolute;
    left: 23%;
    top: 40%;
    transform: translateY(-50%);

    /deep/ {
      .el-input__icon {
        line-height: 32px;
      }
    }
  }

  .item-box {
    margin: 10px 0 16px 0;
  }

  .line-box {
    display: inline-block;
    margin-left: 10px;
  }

  .mglr8 {
    margin: 0 8px;
  }

  .mgl8 {
    margin-left: 8px;
  }

  .label-box {
    display: inline-block;
    width: 90px;
    vertical-align: middle;
    text-align: right;
  }

  .bold-text {
    color: #333;
    font-weight: bold;
  }

  /deep/ .el-input__inner {
    height: 32px;
  }
}

.line-txt {
  line-height: 20px;
}

.tips-text {
  margin-left: 6px;
  color: #c6c1c1;
}

.title-desc{
  margin-left:10px
  color: #999
}
.mr4
    margin: 20px 0 0 2px;
.fc999
    color: #999;
</style>
