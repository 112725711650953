<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Recovery_number')">
                <el-input
                  type="text"
                  v-model="formInline.recoverCode"
                  placeholder="请输入"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Task_status')">
                <el-select v-model.trim="formInline.taskStatus" size="15">
                  <el-option
                    v-for="(item, index) in taskList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Initiation_time')">
                <timeRangePick
                  @timeChange="timeChangeQR"
                  ref="timeRangePickerQR"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
              <el-form-item :label="$t('searchModule.Task_type')">
                <el-select v-model.trim="formInline.taskType" size="15">
                  <el-option
                    v-for="(item, index) in taskTypeList"
                    :label="item.label"
                    :value="item.value"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="handleSearch"
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left">
              <el-button type="primary" icon="el-icon-plus" @click="handleCeated('1')"
                >创建短信任务</el-button
              >
              <el-button type="primary" icon="el-icon-plus" @click="handleCeated('2')"
                >创建电话任务</el-button
              >
            </div>
          </div>
          <!-- <el-form-item> -->
          <!-- <el-button type="primary" icon="el-icon-delete" @click="resetForm()">清空</el-button> -->
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            v-for="item in tableCols"
            :key="item.prop"
            :prop="item.prop"
            align="center"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="操作" align="center" width="90">
            <template slot-scope="scope">
              <el-dropdown
                style="color: #20a0ff; cursor: pointer"
                v-if="scope.row.taskStatus != '1'"
                @command="handleCommand($event, scope.row)"
              >
                <el-button type="text" size="small" style="padding: 0"
                  >操作<i class="el-icon-arrow-down"
                /></el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="scope.row.taskStatus == '2'" command="1"
                    >编辑</el-dropdown-item
                  >
                  <el-dropdown-item command="2">查看</el-dropdown-item>
                  <el-dropdown-item v-if="scope.row.taskStatus == '2'" command="3"
                    >删除</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <task-sms
      v-if="dialogVisible"
      :type="dialogType"
      :dialogVisible="dialogVisible"
      @back="
        dialogVisible = false;
        handleSearch();
      "
      @close="dialogVisible = false"
    />
  </div>
</template>
<script>
import timeRangePick from "@/components/timePicker";
import taskSms from "@/components/task/taskSms";
import { getBeforeDate } from "@/common/js/utils";
export default {
  data() {
    const taskList = [
      {
        label: "全部",
        value: "",
      },
      {
        label: "创建中",
        value: "1",
      },
      {
        label: "待提交",
        value: "2",
      },
      {
        label: "待审核",
        value: "3",
      },
      {
        label: "已驳回",
        value: "4",
      },
      {
        label: "已超时",
        value: "5",
      },
      {
        label: "生效中",
        value: "6",
      },
      {
        label: "已完结",
        value: "7",
      },
    ];
    return {
      dialogType: "",
      dialogVisible: false,
      defalutDate: [],
      formInline: {
        recoverCode: "",
        taskStatus: "",
        createTimeStart: "",
        createTimeEnd: "",
        taskType: "",
      },
      rule: {},
      taskTypeList: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "短信",
          value: "1",
        },
        {
          label: "电话",
          value: "2",
        },
      ],
      taskList: taskList,
      loading: false,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      tableData: [],
      tableCols: [
        {
          prop: "recoverCode",
          label: "追缴编号",
          width: "",
        },
        {
          prop: "taskManagerName",
          label: "追缴负责人",
          width: "",
        },
        {
          prop: "taskStatusDesc",
          label: "任务状态",
          width: "",
          // formatter: (row, column) => {
          //   const taskArr = taskList.filter((item) => {
          //     return row.taskStatus == item.value;
          //   });
          //   return taskArr[0].label;
          // },
        },

        {
          prop: "createdTime",
          label: "发起时间",
          width: "",
        },
        {
          prop: "taskTypeDesc",
          label: "任务类型",
          width: "",
        },
        {
          prop: "reviewerName",
          label: "任务审核人",
          width: "",
        },
        {
          prop: "revieTime",
          label: "审核时间",
          width: "",
        },
        {
          prop: "recoverMoney",
          label: "追缴金额",
          width: "",
          formatter: (row, column) => {
            if (row.recoverMoney) {
              if (row.recoverMoney >= 0) {
                return Number(row.recoverMoney / 100).toFixed(2) + "元";
              } else {
                return row.recoverMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
        {
          prop: "carNum",
          label: "追缴车辆总数",
          width: "",
        },
        {
          prop: "touchableCarNum",
          label: "可触达车辆数",
          width: "",
        },
        {
          prop: "touchablePercent",
          label: "预估触达率",
          width: "",
        },
        {
          prop: "touchableMoney",
          label: "可触达金额",
          width: "",
          formatter: (row, column) => {
            if (row.touchableMoney) {
              if (row.touchableMoney >= 0) {
                return Number(row.touchableMoney / 100).toFixed(2) + "元";
              } else {
                return row.touchableMoney + "元";
              }
            } else {
              return "0.00元";
            }
          },
        },
      ],
    };
  },
  created() {
    this.dateInit();
    this.searchData();
  },
  methods: {
    handleSearch() {
      this.pageNum = 1;
      this.searchData();
    },
    // 创建短信任务
    handleCeated(type) {
      this.dialogType = type;
      this.dialogVisible = true;
    },
    // 操作细则
    handleCommand(command, row) {
      if (command === "1") {
        this.$router.push({
          path: "/taskRecoveryOperation",
          query: {
            id: row.id,
            type: row.taskType,
            detailType: "1",
            back: this.$route.path,
          },
        });
      } else if (command === "2") {
        this.$router.push({
          path: "/taskRecoveryOperation",
          query: {
            id: row.id,
            type: row.taskType,
            detailType: "2",
            back: this.$route.path,
          },
        });
      } else if (command === "3") {
        this.$confirm("确定删除该项追缴任务?", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.handleDeletTask(row.id);
          })
          .catch(() => {});
      }
    },
    handleDeletTask(id) {
      const params = {
        id: id,
      };
      let opt = {
        method: "post",
        url: "/acb/2.0/recoverTask/delete",
        data: params,
        success: (res) => {
          if (res.state == 0) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.handleSearch();
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.taskStatus == "1") {
        return "created-row";
      }
      return "";
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    dateInit() {
      this.defalutDate = [getBeforeDate(31) + " 00:00:00", getBeforeDate() + " 23:59:59"];
      this.formInline.createTimeStart = this.defalutDate[0];
      this.formInline.createTimeEnd = this.defalutDate[1];
    },
    searchData() {
      this.loading = true;
      let opt = {
        method: "get",
        url: "/acb/2.0/recoverTask/list",
        data: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.msg, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
          this.loading = false;
        },
        fail: () => {
          this.loading = false;
        },
      };
      this.$request(opt);
    },
    timeChangeQR(timeArr) {
      this.formInline.createTimeStart = timeArr[0];
      this.formInline.createTimeEnd = timeArr[1];
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    resetForm() {
      this.formInline = {
        recoverCode: "",
        taskStatus: "",
      };
      this.dateInit();
    },
  },
  components: {
    timeRangePick,
    taskSms,
  },
};
</script>
<style lang="stylus" scoped>
/deep/ {
  .el-table .created-row {
    background: #d3e4ca;
    td.el-table__cell {
      background: #d3e4ca !important;
    }
  }
}
</style>
